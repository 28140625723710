import { SliderItem as SliderItemCore } from '@studiometa/ui';

/**
 * withBreakpointObserver behavior component
 */
export default class Slider extends SliderItemCore {
  static config = {
    name: 'SliderItem',
  };

  /**
   * intersected
   * @param {*} isIntersecting
   */
  intersected([{ isIntersecting }]) {
    this.isVisible = isIntersecting;
  }
}
