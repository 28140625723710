import { withBreakpointObserver } from '@studiometa/js-toolkit';
import { ScrollAnimationParent } from '@studiometa/ui';

/**
 * withBreakpointObserver behavior component
 */
export default class ScrollAnimationSlider extends withBreakpointObserver(ScrollAnimationParent) {
  static config = {
    name: '',
    options: {
      activeBreakpoints: {
        type: String,
        default: 'm l xl 2xl xxl 3xl',
      },
    },
    components: { ...ScrollAnimationParent.config.components },
  };
}
