import { Base } from '@studiometa/js-toolkit';

/**
 * App class.
 * @extends {Base}
 */
export default class Form extends Base {
  static config = {
    name: 'Form',
    refs: ['submitter', 'success', 'content', 'honey'],
    log: true,
  };

  utmsParams = [];

  landing = null;

  /**
   *
   */
  mounted() {
    this.landing = window.location.pathname;
    const url = window.location.href;
    const urlParams = new URLSearchParams(url);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, val] of urlParams) {
      // check for prefix
      if (key.startsWith('utm_')) {
        this.utmsParams.push(`${key}=${val}`);
      }
    }
    this.utmsParams.join(' | ');
  }

  /**
   * @param {Event} e
   */
  async onSubmit(e) {
    if (this.$refs.honey.checked) {
      this.$log('check');
      return;
    }
    e.preventDefault();
    this.$refs.submitter.setAttribute('disabled', '');
    const formValues = new FormData(this.$el);
    const response = await fetch(new URL('/submit', window.location.origin), {
      method: 'POST',
      body: JSON.stringify({
        form: formValues.get('form'),
        name: formValues.get('name'),
        lastname: formValues.get('lastname'),
        enterprise: formValues.get('enterprise'),
        email: formValues.get('email'),
        phone: formValues.get('phone'),
        message: formValues.get('message'),
        landing: this.landing,
        utm: this.utmsParams,
      }),
    });
    const res = await response.status;
    if (res === 200) {
      this.$refs.content.classList.add('hidden');
      this.$refs.success.classList.remove('hidden');
      this.$refs.submitter.classList.add('hidden');
    }
  }
}
