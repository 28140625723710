import { withBreakpointObserver } from '@studiometa/js-toolkit';
import { Slider as SliderCore, SliderDots } from '@studiometa/ui';
import SliderItem from './SliderItem.js';

/**
 * withBreakpointObserver behavior component
 */
export default class Slider extends withBreakpointObserver(SliderCore) {
  static config = {
    name: 'Slider',
    options: {
      activeBreakpoints: {
        type: String,
        default: 'xxs xs s m l',
      },
    },
    components: { ...SliderCore.config.components, SliderItem, SliderDots },
  };
}
