import { Base, createApp } from '@studiometa/js-toolkit';
import {
  ScrollAnimation,
  ScrollAnimationWithEase,
  ScrollAnimationParent,
  ScrollAnimationChild,
} from '@studiometa/ui';
import Slider from './components/Slider.js';
import Testimonies from './components/Testimonies.js';
import ScrollReveal from './components/ScrollReveal.js';
import ScrollAnimationSlider from './components/ScrollAnimationSlider.js';
import Anchors from './components/Anchors.js';
import Form from './components/Form.js';

/**
 * @typedef {{
 *   $children: {
 *     ScrollAnimation: ScrollAnimation[]
 *     ScrollAnimationParent: ScrollAnimationParent[]
 *     ScrollAnimationChild: ScrollAnimationChild[]
 *     ScrollAnimationWithEase: ScrollAnimationWithEase[]
 *     ScrollReveal: ScrollReveal[]
 *     Slider: Slider[]
 *     ScrollAnimationSlider: ScrollAnimationSlider[]
 *     Testimonies: Testimonies[]
 *     Anchors: Anchors[]
 *     Form: Form[]
 *   }
 * }} AppProps
 */

/**
 * App class.
 * @extends {Base<AppProps>}
 */
class App extends Base {
  /**
   * Config.
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'App',
    components: {
      Anchors,
      ScrollAnimation,
      ScrollAnimationWithEase,
      ScrollReveal,
      ScrollAnimationParent,
      ScrollAnimationChild,
      ScrollAnimationSlider,
      Slider,
      Testimonies,
      Form,
    },
  };

  root = null;

  /**
   * mounted
   */
  mounted() {
    this.root = document.querySelector(':root');
    this.setCSSVars();
  }

  /**
   * mounted
   */
  resized() {
    this.setCSSVars();
  }

  /**
   * Set CSS Vars
   */
  setCSSVars() {
    const width = window.innerWidth / 100;
    const height = window.innerHeight / 100;
    this.root.style.setProperty('--vw', `${width}px`);
    this.root.style.setProperty('--vh', `${height}px`);
  }
}

export default createApp(App, {
  features: {
    asyncChildren: false,
  },
});
